<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo" href="/">
      <b-img
        fluid
        style="width: 240px; max-height: 60px"
        :src="imgLogo"
        alt="Logo"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Email confirmation</h2>
        <p class="mb-2">
          Email confirmation will give you access to all the basic features of
          our service.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          @click="confirmEmailRequest"
        >
          Confirm email
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import { confirmRequest, loginUser } from "@/auth/utils";

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
      imgLogo: require("@/assets/images/logo/3Digital_logo.png"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    confirmEmailRequest() {
      const { uid, token } = this.$route.params;

      confirmRequest
        .post(`auth/users/activate/${uid}/${token}`, null)
        .then(this.$router.push({ name: "auth-login" }));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
